// import SelectInput from '@material-ui/core/Select/SelectInput';
import { useLocation } from 'react-router';
import React from 'react';
import {
  Edit,
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SelectInput
} from 'react-admin';

const redirect = (basePath, id, data) => {
  if (data.infosystem === null) {
    return '/';
  }
  return `/infosystems/${data.infosystem}/show/1`;
}

export const InfosystemrightEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect={redirect}>
      <ReferenceInput label="База" source="infosystem" reference="Infosystems">
        <AutocompleteInput optionsText="База" />
      </ReferenceInput>
      <ReferenceInput label="Пользователь" source="employee" reference="Employees">
        <AutocompleteInput optionsText="Пользователь" />
      </ReferenceInput>
      <ReferenceInput label="Право" source="right" reference="Infosystemrightstypes">
        <SelectInput optionsText="Право" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const InfosystemrightCreate = (props) => {
  const location = useLocation();
  const redirect = location.state.record.infosystem === undefined ? `/Employees/${location.state.record.employee}/show/2` : `/Infosystems/${location.state.record.infosystem}/show/1`;
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput label="База" source="infosystem" reference="Infosystems">
          <AutocompleteInput optionsText="База" />
        </ReferenceInput>
        <ReferenceInput label="Пользователь" source="employee" reference="Employees">
          <AutocompleteInput optionsText="Пользователь" />
        </ReferenceInput>
        <ReferenceInput label="Право" source="right" reference="Infosystemrightstypes">
          <SelectInput optionsText="Право" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
};
