import React from 'react';
import AddIcon from "@material-ui/icons/AddBox";
import { Button } from "react-admin";
import { Link } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
}));

const AddNewButton = ({ record, reference, state }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={{
        pathname: `/${reference}/create`,
        // Here we specify the initial record for the create view
        state: { record: { employee: record.id } },
      }}
      label="Добавить"
    >
      <AddIcon />
    </Button>
  );
};

export default AddNewButton;