import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
 } from 'react-admin';

export const OfficeList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" label="Название" />
      <TextField source="address" label="Адрес" />
      <EditButton basePath="/Offices" label="" />
    </Datagrid>
  </List>
);

export const OfficeEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" label="Название" />
      <TextInput source="address" label="Адрес" />
    </SimpleForm>
  </Edit>
);

export const OfficeCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" label="Название" />
      <TextInput source="address" label="Адрес" />
    </SimpleForm>
  </Create>
);

