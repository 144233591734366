import React from 'react';
import { useLocation } from 'react-router';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
} from 'react-admin';

export const TicketCommentCreate = props => {
  const location = useLocation();
  return (<Create {...props}>
    <SimpleForm redirect={`/tickets/${location.state.record.ticket}/show/1`}>
      <TextInput source="ticket" label="Заявка" disabled />
      <TextInput source="content" label="Комментарий" multiline />
      <BooleanInput label="Уведомить пользователя" source="notifyUser" />
    </SimpleForm>
  </Create>
  )
}

