import React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import simpleRestProvider from './helpers/ra-strapi-rest';
import authProvider from './helpers/AuthProvider';
import Cookies from './helpers/cookies';

import { ClientList, ClientEdit, ClientCreate } from './resources/clients'
import { PasswordList, PasswordEdit, PasswordCreate } from './resources/passwords'
import { EmployeeList, EmployeeShow, EmployeeEdit, EmployeeCreate } from './resources/employees'
import { OfficeList, OfficeEdit, OfficeCreate } from './resources/offices'
import { TicketList, TicketEdit, TicketCreate, TicketShow } from './resources/tickets'
import { TicketCommentCreate } from './resources/ticketcomments'
import { InfosystemrightstypesList, InfosystemrightstypesEdit, InfosystemrightstypesCreate } from './resources/infosystemrightstypes'
import { InfosystemList, InfosystemShow, InfosystemCreate, InfosystemEdit } from './resources/infosystems'
import { InfosystemrightCreate, InfosystemrightEdit } from './resources/infosystemrights'

import PeopleIcon from '@material-ui/icons/People';
import DomainIcon from '@material-ui/icons/Domain';
import LockIcon from '@material-ui/icons/Lock';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MailIcon from '@material-ui/icons/Mail';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = Cookies.getCookie('token')
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider('https://api.i-bit.ru', httpClient);


function App() {
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      <Resource name="Tickets" icon={MailIcon} options={{ label: 'Заявки' }} list={TicketList} edit={TicketEdit} create={TicketCreate} show={TicketShow} />
      <Resource name="Ticket-statuses" />
      <Resource name="Users" />
      <Resource name="Ticket-comments" create={TicketCommentCreate} />
      <Resource name="Employees" icon={PeopleIcon} options={{ label: 'Пользователи' }} list={EmployeeList} edit={EmployeeEdit} show={EmployeeShow} create={EmployeeCreate} />
      <Resource name="Clients" icon={DomainIcon} options={{ label: 'Организации' }} list={ClientList} edit={ClientEdit} create={ClientCreate} />
      <Resource name="Passwords" icon={LockIcon} options={{ label: 'Пароли' }} list={PasswordList} edit={PasswordEdit} create={PasswordCreate} />
      <Resource name="Offices" icon={ApartmentIcon} options={{ label: 'Офисы' }} list={OfficeList} edit={OfficeEdit} create={OfficeCreate} />
      <Resource name="Infosystemrightstypes" options={{ label: '1с типы доступа' }} list={InfosystemrightstypesList} create={InfosystemrightstypesCreate} edit={InfosystemrightstypesEdit} />
      <Resource name="Infosystems" options={{ label: '1с базы' }} list={InfosystemList} show={InfosystemShow} create={InfosystemCreate} edit={InfosystemEdit} />
      <Resource name="Infosystem-rights" create={InfosystemrightCreate} edit={InfosystemrightEdit} />
    </Admin>
  );
}

export default App;
