import React from 'react';
import { useLocation } from 'react-router';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  Show,
  SimpleShowLayout,
  Filter,
  UrlField
} from 'react-admin';

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
  </Filter>
);

export const PasswordList = props => (
  <List {...props} filters={<ListFilter />} >
    <Datagrid>
      <TextField source="name" label="Название" />
      <UrlField source="link" label="Ссылка" />
      <TextField source="user" label="Пользователь" />
      <TextField source="password" label="Пароль" />
      <EditButton basePath="/Passwords" label="" />
    </Datagrid>
  </List>
);

export const PasswordShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Название" />
      <TextField source="link" label="Ссылка" />
      <TextField source="user" label="Пользователь" />
      <TextField source="password" label="Пароль" />
    </SimpleShowLayout>
  </Show>
);

const redirect = (basePath, id, data) => {
  if (data.emploee === null) {
    return '/passwords';
  }
  return `/employees/${data.employee}/show/1`;
}

export const PasswordEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="name" label="Название" />
      <TextInput source="link" label="Ссылка" />
      <TextInput source="user" label="Пользователь" />
      <TextInput source="password" label="Пароль" />
    </SimpleForm>
  </Edit>
);

export const PasswordCreate = props => {
  const location = useLocation();

  const redirect = location.state === undefined ? '/passwords' : `/employees/${location.state.record.employee}/show/1`;

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput source="name" label="Название" />
        <TextInput source="link" label="Ссылка" />
        <TextInput source="user" label="Пользователь" />
        <TextInput source="password" label="Пароль" />
      </SimpleForm>
    </Create>
  );  
}


