import React from 'react';
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { Button } from "react-admin";
import { Link } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import { get } from 'lodash'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  DateField,
  TabbedShowLayout,
  Tab,
  Show,
  ReferenceManyField,
  SimpleList,
  Filter,
  BooleanInput,
  AutocompleteInput
} from 'react-admin';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
}));

const AddNewCommentButton = ({ record }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={{
        pathname: "/Ticket-comments/create",
        // Here we specify the initial record for the create view
        state: { record: { ticket: record.id } },
      }}
      label="Add a comment"
    >
      <ChatBubbleIcon />
    </Button>
  );
};

const ListFilter = props => (
  <Filter {...props}>
    <BooleanInput source="is_open" label="Открытые" alwaysOn />
    <ReferenceInput label="Статус" source="status" reference="Ticket-statuses">
      <SelectInput optionsText="name" />
    </ReferenceInput>
  </Filter>
);

export const TicketList = props => (
  <List {...props} filters={<ListFilter />} filterDefaultValues={{ is_open: true }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <DateField source="created_at" label="Дата" locales="ru-RU" showTime />
      <ReferenceField label="Пользователь" source="employee.id" reference="Employees">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="title" label="Тема" />
      <ReferenceField label="Статус" source="status.id" reference="Ticket-statuses">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Ответственный" source="technician.id" reference="Users">
        <TextField source="name" />
      </ReferenceField>
      <EditButton basePath="/Tickets" label="" />
    </Datagrid>
  </List>
);

export const TicketShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Заявка">
        <TextField source="id" />
        <DateField source="created_at" label="Дата" locales="ru-RU" showTime />
        <ReferenceField label="Пользователь" source="employee" reference="Employees">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="title" label="Тема" />
        <ReferenceField label="Статус" source="status" reference="Ticket-statuses">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Ответственный" source="technician" reference="Users">
          <TextField source="name" />
        </ReferenceField>
      </Tab>
      <Tab label="Комментарии">
        <ReferenceManyField reference="Ticket-comments" target="ticket" label="" sort={{ field: 'created_at', order: 'ASC' }}>
          <SimpleList
            linkType={false}
            primaryText={record => record.content}
            secondaryText={record => new Date(record.created_at).toLocaleString('ru-RU') + " " + get(record, 'author.name', 'Пользователь не найден!')}
          />
        </ReferenceManyField>
        <AddNewCommentButton />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const TicketEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput label="Пользователь" source="employee" reference="Employees">
        <AutocompleteInput optionsText="Пользователь" />
      </ReferenceInput>
      <TextInput source="title" label="Тема" />
      <ReferenceInput label="Статус" source="status" reference="Ticket-statuses">
        <SelectInput optionsText="name" />
      </ReferenceInput>
      <ReferenceInput label="Ответственный" source="technician" reference="Users">
        <SelectInput optionsText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const TicketCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput label="Пользователь" source="employee" reference="Employees">
        <AutocompleteInput optionsText="Пользователь" />
      </ReferenceInput>
      <TextInput source="title" label="Тема" />
      <ReferenceInput label="Статус" source="status" reference="Ticket-statuses">
        <SelectInput optionsText="name" />
      </ReferenceInput>
      <ReferenceInput label="Ответственный" source="technician" reference="Users">
        <SelectInput optionsText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

