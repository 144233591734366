import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanField,
  BooleanInput,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  ReferenceField
} from 'react-admin';
import AddIcon from "@material-ui/icons/AddBox";
import { Button } from "react-admin";
import { Link } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";

export const InfosystemList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="server" label="Сервер" />
      <TextField source="name" label="Имя базы" />
      <TextField source="descr" label="Описание" />
      <BooleanField source="is_active" label="Действующая" />
      <EditButton basePath="/Infosystems" label="" />
    </Datagrid>
  </List>
);

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
}));

const AddNewButton = ({ record, reference, state }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={{
        pathname: `/${reference}/create`,
        // Here we specify the initial record for the create view
        state: { record: { infosystem: record.id } },
      }}
      label="Добавить"
    >
      <AddIcon />
    </Button>
  );
};

export const InfosystemShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="База">
        <TextField source="server" label="Сервер" />
        <TextField source="name" label="Имя базы" />
        <TextField source="descr" label="Описание" />
        <BooleanField source="is_active" label="Действующая" />
      </Tab>
      <Tab label="Права">
        <ReferenceManyField reference="Infosystem-rights" target="infosystem" label="">
          <Datagrid>
            <ReferenceField label="Пользователь" source="employee.id" reference="Employees">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Право" source="right.id" reference="Infosystemrightstypes">
              <TextField source="name" />
            </ReferenceField>
            <EditButton basePath="/Infosystem-rights" label=""/>
          </Datagrid>
        </ReferenceManyField>
        <AddNewButton reference="Infosystem-rights" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const InfosystemEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="server" label="Сервер" />
      <TextInput source="name" label="Имя базы" />
      <TextInput source="descr" label="Описание" />
      <BooleanInput source="is_active" label="Действующая" />
    </SimpleForm>
  </Edit>
);

export const InfosystemCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="server" label="Сервер" />
      <TextInput source="name" label="Имя базы" />
      <TextInput source="descr" label="Описание" />
      <BooleanInput source="is_active" label="Действующая" />
    </SimpleForm>
  </Create>
);

