import React from 'react';
import { 
  List, 
  Datagrid, 
  Edit, 
  Create, 
  SimpleForm, 
  DateField, TextField, EditButton, TextInput, DateInput } from 'react-admin';

export const ClientList = (props) => (
    <List {...props} perPage={50}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" label="Название"/>
            <EditButton basePath="/Clients" />
        </Datagrid>
    </List>
);

const ClientTitle = ({ record }) => {
    return <span>Client {record ? `"${record.name}"` : ''}</span>;
};

export const ClientEdit = (props) => (
    <Edit title={<ClientTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export const ClientCreate = (props) => (
    <Create title="Create a Client" {...props}>
        <SimpleForm>
            <TextInput source="name" label="Название" />
        </SimpleForm>
    </Create>
);
