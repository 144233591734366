import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
} from 'react-admin';

export const InfosystemrightstypesList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="name" label="Название" />
      <EditButton basePath="/Infosystemrightstypes" label="" />
    </Datagrid>
  </List>
);

export const InfosystemrightstypesEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" label="Название" />
    </SimpleForm>
  </Edit>
);

export const InfosystemrightstypesCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" label="Название" />
    </SimpleForm>
  </Create>
);

