import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  TextInput,
  NumberField,
  BooleanField,
  NumberInput,
  BooleanInput,
  Show,
  SimpleShowLayout,
  DateInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Filter,
  SearchInput,
  EmailField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField
} from 'react-admin';
import AddNewButton from '../components/addnewbutton'

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
    <BooleanInput source="is_fired" label="Уволеные" alwaysOn />
    <ReferenceInput label="Организация" source="client" reference="Clients">
      <SelectInput optionsText="name" />
    </ReferenceInput>
  </Filter>
);

export const EmployeeList = props => (
  <List {...props} filters={<ListFilter />} >
    <Datagrid rowClick="show">
      <TextField source="name" label="ФИО" />
      <ReferenceField label="Организация" source="client.id" reference="Clients">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Офис" source="office.id" reference="Offices">
        <TextField source="name" />
      </ReferenceField>
      <EmailField source="email" />
      <TextField source="phone_mobile" label="Мобильный" />
      <TextField source="phone_int" label="Внутренний" />
      <EditButton basePath="/Employees" label="" />
    </Datagrid>
  </List>
);

export const EmployeeShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Пользователь">
        <TextField source="name" label="ФИО" />
        <ReferenceField label="Организация" source="client" reference="Clients">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Офис" source="office.id" reference="Offices">
          <TextField source="name" />
        </ReferenceField>
        <EmailField source="email" />
        <TextField source="phone_mobile" label="Мобильный" />
        <TextField source="phone_int" label="Внутренний" />
        <TextField source="login" label="Логин" />
        <TextField source="password" label="Пароль" />
        <BooleanField source="is_fired" label="Уволен" />
      </Tab>
      <Tab label="Пароли">
        <ReferenceManyField reference="Passwords" target="employee" label="">
          <Datagrid>
            <TextField source="name" label="Название" />
            <TextField source="link" label="Ссылка" />
            <TextField source="user" label="Пользователь" />
            <TextField source="password" label="Пароль" />
            <EditButton basePath="/Passwords" label="" />
          </Datagrid>
        </ReferenceManyField>
        <AddNewButton reference="Passwords" />
      </Tab>
      <Tab label="Права 1C">
        <ReferenceManyField reference="Infosystem-rights" target="employee" label="">
          <Datagrid>
            <ReferenceField label="База" source="infosystem.id" reference="Infosystems">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Право" source="right.id" reference="Infosystemrightstypes">
              <TextField source="name" />
            </ReferenceField>
            <EditButton basePath="/Infosystem-rights" label="" />
          </Datagrid>
        </ReferenceManyField>
        <AddNewButton reference="Infosystem-rights" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const EmployeeEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" label="ФИО" />
      <ReferenceInput label="Организация" source="client" reference="Clients">
        <SelectInput optionsText="name" />
      </ReferenceInput>
      <ReferenceInput label="Офис" source="office.id" reference="Offices">
        <SelectInput optionsText="name" />
      </ReferenceInput>
      <TextInput source="email" />
      <TextInput source="phone_int" label="Внутренний" />
      <TextInput source="phone_mobile" label="Мобильный" />
      <TextInput source="login" label="Логин" />
      <TextInput source="password" label="Пароль" />
      <BooleanInput source="is_fired" label="Уволен" />
    </SimpleForm>
  </Edit>
);

export const EmployeeCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" label="ФИО" />
      <ReferenceInput label="Организация" source="client" reference="Clients">
        <SelectInput optionsText="name" />
      </ReferenceInput>
      <ReferenceInput label="Офис" source="office.id" reference="Offices">
        <SelectInput optionsText="name" />
      </ReferenceInput>
      <TextInput source="email" />
      <TextInput source="phone_int" label="Внутренний" />
      <TextInput source="phone_mobile" label="Мобильный" />
      <TextInput source="login" label="Логин" />
      <TextInput source="password" label="Пароль" />
      <BooleanInput source="is_fired" label="Уволен" />
    </SimpleForm>
  </Create>
);

